































































































































































































































































































































































































































import useParticipant from "@/use/participant";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import countriesList from "@/assets/data/countries.json";
import useAuthPermission from "@/use/authPermissions";
import useMisc from "@/use/misc";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    participantId: {
      type: String,
      required: true,
      default: "",
    },
    eventId: {
      type: String,
      required: true,
      default: "",
    },
    headersLength: {
      type: Number,
      required: false,
      default: 0,
    },
    currency: {
      type: String,
      required: false,
      default: "",
    },
    specializations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  components: {
    MParticipantPresenceList: () =>
      import(
        "@/components/molecules/participant/m-participant-presence-list.vue"
      ),
  },

  setup(props, { root }) {
    const state = reactive({
      presenceDialog: false,
      additionalServices: [],
      loading: false,
      error: false,
      item: {} as any,
    });

    const {
      getParticipantStatusName,
      getParticipantInvoiceType,
    } = useParticipant({ root });
    const { getLanguageVersion } = useMisc({ root });
    const { participantsManagement } = useAuthPermission({
      root,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${props.participantId}`)
        .then(({ data: { participant } }) => {
          state.item = participant;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const primaryRegistrationFormAdditionalFields = computed(() => {
      if (state.item.event && state.item.event.registrationFormTemplate) {
        const fields = state.item.event.registrationFormTemplate.groups
          .filter(
            (group: any) =>
              group.type !== "consents" &&
              group.type !== "invoice" &&
              group.type !== "services" &&
              group.type !== "accommodation"
          )
          .map((group: any) => group.fields)
          .flat()
          .filter(
            (field: any) =>
              field.type === "phone" ||
              field.type === "number" ||
              field.type === "text" ||
              field.type === "textarea" ||
              field.type === "radioGroup" ||
              field.type === "checkboxGroup" ||
              field.type === "select"
          );

        return fields;
      }
      return [];
    });

    const secondaryRegistrationFormAdditionalFields = computed(() => {
      if (
        state.item.event &&
        state.item.event.secondaryRegistrationFormTemplate
      ) {
        const fields = state.item.event.secondaryRegistrationFormTemplate.groups
          .filter(
            (group: any) =>
              group.type !== "consents" &&
              group.type !== "invoice" &&
              group.type !== "services" &&
              group.type !== "accommodation"
          )
          .map((group: any) => group.fields)
          .flat()
          .filter(
            (field: any) =>
              field.type === "phone" ||
              field.type === "number" ||
              field.type === "text" ||
              field.type === "textarea" ||
              field.type === "radioGroup" ||
              field.type === "checkboxGroup" ||
              field.type === "select"
          );

        return fields;
      }
      return [];
    });

    const additionalServices = () => {
      if (state.item.services && state.item.services.length) {
        state.additionalServices = state.item.services
          .map((el: any) => el.service)
          .reduce((acc: any, value: any) => {
            if (!acc[value.id]) {
              acc[value.id] = [];
            }
            acc[value.id].push(value);
            return acc;
          }, {});
      }
    };

    onMounted(additionalServices);

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/participant/`
    );

    return {
      state,
      countriesList,
      getParticipantStatusName,
      getParticipantInvoiceType,
      getLanguageVersion,
      participantsManagement,
      filesUrl,
      primaryRegistrationFormAdditionalFields,
      secondaryRegistrationFormAdditionalFields,
    };
  },
});
